:root {
  --blue: #0061f2;
  --indigo: #5800e8;
  --purple: #6900c7;
  --pink: #e30059;
  --red: #e81500;
  --orange: #f76400;
  --yellow: #f4a100;
  --green: #00ac69;
  --teal: #00ba94;
  --cyan: #00cfd5;
  --white: #fff;
  --gray: #69707a;
  --gray-dark: #363d47;
  --primary: #0061f2;
  --secondary: #6900c7;
  --success: #00ac69;
  --info: #00cfd5;
  --warning: #f4a100;
  --danger: #e81500;
  --light: #f2f6fc;
  --dark: #212832;
  --black: #000;
  --white: #fff;
  --red: #e81500;
  --orange: #f76400;
  --yellow: #f4a100;
  --green: #00ac69;
  --teal: #00ba94;
  --cyan: #00cfd5;
  --blue: #0061f2;
  --indigo: #5800e8;
  --purple: #6900c7;
  --pink: #e30059;
  --red-soft: #f1e0e3;
  --orange-soft: #f3e7e3;
  --yellow-soft: #f2eee3;
  --green-soft: #daefed;
  --teal-soft: #daf0f2;
  --cyan-soft: #daf2f8;
  --blue-soft: #dae7fb;
  --indigo-soft: #e3ddfa;
  --purple-soft: #e4ddf7;
  --pink-soft: #f1ddec;
  --primary-soft: #dae7fb;
  --secondary-soft: #e4ddf7;
  --success-soft: #daefed;
  --info-soft: #daf2f8;
  --warning-soft: #f2eee3;
  --danger-soft: #f1e0e3;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "72", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
footer,
header,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: "72", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #69707a;
  text-align: left;
  background-color: #f2f6fc;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
dl,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul ul {
  margin-bottom: 0;
}
b {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
a {
  color: #0061f2;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0042a6;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #363d47;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2.3rem;
}
.h3,
h3 {
  font-size: 2.2rem;
}
.h4,
h4 {
  font-size: 2rem;
}
.h5,
h5 {
  font-size: 1.7rem;
}
.h6,
h6 {
  font-size: 1.4rem;
}
.lead {
  font-size: 1.2rem;
  font-weight: 400;
}
.display-1 {
  font-size: 5.5rem;
  font-weight: 600;
  line-height: 1.2;
}
.display-2 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.5;
}
.display-3 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}
.display-4 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.5;
}
.display-5 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.3;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 0.875em;
  font-weight: 400;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
code {
  font-size: 87.5%;
  color: #e30059;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}
.col-form-label-lg {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  font-size: 1rem;
  line-height: 1;
}
.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.75rem;
  line-height: 1;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid #222;
  box-shadow: 5px 5px 0 #222;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #69707a;
  text-decoration: none;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.25);
}
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-primary {
  display: inline-block;
  font-weight: 400;
  color: #222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid #222;
  box-shadow: 5px 5px 0 #222;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary:hover {
  display: inline-block;
  font-weight: 400;
  color: #222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid #111;
  box-shadow: 5px 5px 0 #111;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary:focus {
  display: inline-block;
  font-weight: 400;
  color: #222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid #111;
  box-shadow: 5px 5px 0 #111;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary:disabled {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #004dbf;
  border-color: #0047b2;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5500a1;
  border-color: #4e0094;
}
.btn-secondary:focus {
  color: #fff;
  background-color: #5500a1;
  border-color: #4e0094;
  box-shadow: 0 0 0 0.2rem rgba(128, 38, 207, 0.5);
}
.btn-secondary:disabled {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4e0094;
  border-color: #470087;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 38, 207, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.btn-success:hover {
  color: #fff;
  background-color: #008652;
  border-color: #00794a;
}
.btn-success:focus {
  color: #fff;
  background-color: #008652;
  border-color: #00794a;
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 128, 0.5);
}
.btn-success:disabled {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #00794a;
  border-color: #006c42;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 128, 0.5);
}
.btn-warning {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ce8800;
  border-color: #c17f00;
}
.btn-warning:focus {
  color: #fff;
  background-color: #ce8800;
  border-color: #c17f00;
  box-shadow: 0 0 0 0.2rem rgba(246, 175, 38, 0.5);
}
.btn-warning:disabled {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #c17f00;
  border-color: #b47700;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 175, 38, 0.5);
}
.btn-light {
  color: #212832;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.btn-light:hover {
  color: #212832;
  background-color: #d3e0f5;
  border-color: #c9d9f2;
}
.btn-light:focus {
  color: #212832;
  background-color: #d3e0f5;
  border-color: #c9d9f2;
  box-shadow: 0 0 0 0.2rem rgba(211, 215, 222, 0.5);
}
.btn-light:disabled {
  color: #212832;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
  color: #212832;
  background-color: #c9d9f2;
  border-color: #bed2f0;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 215, 222, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.btn-dark:hover {
  color: #fff;
  background-color: #12161b;
  border-color: #0d0f13;
}
.btn-dark:focus {
  color: #fff;
  background-color: #12161b;
  border-color: #0d0f13;
  box-shadow: 0 0 0 0.2rem rgba(66, 72, 81, 0.5);
}
.btn-dark:disabled {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0d0f13;
  border-color: #08090c;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 72, 81, 0.5);
}
.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:not(:disabled):not(.disabled).active:focus,
.btn-black:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-blue {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-blue:hover {
  color: #fff;
  background-color: #0052cc;
  border-color: #004dbf;
}
.btn-blue:focus {
  color: #fff;
  background-color: #0052cc;
  border-color: #004dbf;
  box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}
.btn-blue:disabled {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #004dbf;
  border-color: #0047b2;
}
.btn-blue:not(:disabled):not(.disabled).active:focus,
.btn-blue:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}
.btn-outline-primary {
  color: #0061f2;
  border-color: #0061f2;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}
.btn-outline-primary:disabled {
  color: #0061f2;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}
.btn-outline-secondary {
  color: #6900c7;
  border-color: #6900c7;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}
.btn-outline-secondary:disabled {
  color: #6900c7;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}
.btn-outline-success {
  color: #00ac69;
  border-color: #00ac69;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}
.btn-outline-success:disabled {
  color: #00ac69;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}
.btn-outline-warning {
  color: #f4a100;
  border-color: #f4a100;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}
.btn-outline-warning:disabled {
  color: #f4a100;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}
.btn-outline-light {
  color: #f2f6fc;
  border-color: #f2f6fc;
}
.btn-outline-light:hover {
  color: #212832;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 252, 0.5);
}
.btn-outline-light:disabled {
  color: #f2f6fc;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #212832;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 252, 0.5);
}
.btn-outline-dark {
  color: #212832;
  border-color: #212832;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 40, 50, 0.5);
}
.btn-outline-dark:disabled {
  color: #212832;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 40, 50, 0.5);
}
.btn-outline-black {
  color: #000;
  border-color: #000;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-black:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled).active,
.btn-outline-black:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.btn-outline-black:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-blue {
  color: #0061f2;
  border-color: #0061f2;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-outline-blue:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}
.btn-outline-blue:disabled {
  color: #0061f2;
  background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled).active,
.btn-outline-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.btn-outline-blue:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #0061f2;
  text-decoration: none;
}
.btn-link:hover {
  color: #0042a6;
  text-decoration: underline;
}
.btn-link:focus {
  text-decoration: underline;
}
.btn-link:disabled {
  color: #69707a;
  pointer-events: none;
}
.btn-lg {
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
}
.btn-sm {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
  color: #222;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.35rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.35rem;
}
.card-title {
  margin-bottom: 1rem;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.35rem;
}
.card-header {
  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}
.card-footer {
  padding: 1rem 1.35rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0.35rem 0.35rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.35rem;
}
.card-img,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.card-img {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0061f2;
  background-color: #fff;
  border: 1px solid #d4dae3;
}
.page-link:hover {
  z-index: 2;
  color: #0042a6;
  text-decoration: none;
  background-color: #e0e5ec;
  border-color: #d4dae3;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-primary {
  color: #fff;
  background-color: #0061f2;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #004dbf;
}
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6900c7;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #4e0094;
}
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #00ac69;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #00794a;
}
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}
.badge-warning {
  color: #fff;
  background-color: #f4a100;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #fff;
  background-color: #c17f00;
}
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}
.badge-light {
  color: #212832;
  background-color: #f2f6fc;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212832;
  background-color: #c9d9f2;
}
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 252, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #212832;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #0d0f13;
}
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 40, 50, 0.5);
}
.badge-black {
  color: #fff;
  background-color: #000;
}
a.badge-black:focus,
a.badge-black:hover {
  color: #fff;
  background-color: #000;
}
a.badge-black:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.badge-blue {
  color: #fff;
  background-color: #0061f2;
}
a.badge-blue:focus,
a.badge-blue:hover {
  color: #fff;
  background-color: #004dbf;
}
a.badge-blue:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}
.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}
.alert-link {
  font-weight: 500;
}
.alert-primary {
  color: #00327e;
  background-color: #ccdffc;
  border-color: #b8d3fb;
}
.alert-primary hr {
  border-top-color: #a0c4fa;
}
.alert-primary .alert-link {
  color: #001e4b;
}
.alert-secondary {
  color: #370067;
  background-color: #e1ccf4;
  border-color: #d5b8ef;
}
.alert-secondary hr {
  border-top-color: #c9a3ea;
}
.alert-secondary .alert-link {
  color: #1c0034;
}
.alert-success {
  color: #005937;
  background-color: #cceee1;
  border-color: #b8e8d5;
}
.alert-success hr {
  border-top-color: #a5e2ca;
}
.alert-success .alert-link {
  color: #002617;
}
.alert-warning {
  color: #7f5400;
  background-color: #fdeccc;
  border-color: #fce5b8;
}
.alert-warning hr {
  border-top-color: #fbdca0;
}
.alert-warning .alert-link {
  color: #4c3200;
}
.alert-light {
  color: #7e8083;
  background-color: #fcfdfe;
  border-color: #fbfcfe;
}
.alert-light hr {
  border-top-color: #e7edf9;
}
.alert-light .alert-link {
  color: #656769;
}
.alert-dark {
  color: #11151a;
  background-color: #d3d4d6;
  border-color: #c1c3c6;
}
.alert-dark hr {
  border-top-color: #b4b6ba;
}
.alert-dark .alert-link {
  color: #000;
}
.alert-black {
  color: #000;
  background-color: #ccc;
  border-color: #b8b8b8;
}
.alert-black hr {
  border-top-color: #ababab;
}
.alert-black .alert-link {
  color: #000;
}
.alert-blue {
  color: #00327e;
  background-color: #ccdffc;
  border-color: #b8d3fb;
}
.alert-blue hr {
  border-top-color: #a0c4fa;
}
.alert-blue .alert-link {
  color: #001e4b;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e0e5ec;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e0e5ec;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.bg-primary {
  background-color: #0061f2 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #004dbf !important;
}
.bg-secondary {
  background-color: #6900c7 !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #4e0094 !important;
}
.bg-success {
  background-color: #00ac69 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #00794a !important;
}
.bg-warning {
  background-color: #f4a100 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #c17f00 !important;
}
.bg-light {
  background-color: #f2f6fc !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #c9d9f2 !important;
}
.bg-dark {
  background-color: #212832 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #0d0f13 !important;
}
.bg-black {
  background-color: #000 !important;
}
a.bg-black:focus,
a.bg-black:hover,
button.bg-black:focus,
button.bg-black:hover {
  background-color: #000 !important;
}
.bg-blue {
  background-color: #0061f2 !important;
}
a.bg-blue:focus,
a.bg-blue:hover,
button.bg-blue:focus,
button.bg-blue:hover {
  background-color: #004dbf !important;
}
.border {
  border: 1px solid #e0e5ec !important;
}
.border-right {
  border-right: 1px solid #e0e5ec !important;
}
.border-bottom {
  border-bottom: 1px solid #e0e5ec !important;
}
.border-0 {
  border: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-primary {
  border-color: #0061f2 !important;
}
.border-secondary {
  border-color: #6900c7 !important;
}
.border-success {
  border-color: #00ac69 !important;
}
.border-warning {
  border-color: #f4a100 !important;
}
.border-light {
  border-color: #f2f6fc !important;
}
.border-dark {
  border-color: #212832 !important;
}
.border-black {
  border-color: #000 !important;
}
.border-blue {
  border-color: #0061f2 !important;
}
.rounded-sm {
  border-radius: 0.25rem !important;
}
.rounded {
  border-radius: 0.35rem !important;
}
.rounded-right {
  border-top-right-radius: 0.35rem !important;
  border-bottom-right-radius: 0.35rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
}
.page-header.navbar-fixed .navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .nav-sticky,
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.m-10 {
  margin: 6rem !important;
}
.mt-10,
.my-10 {
  margin-top: 6rem !important;
}
.mr-10,
.mx-10 {
  margin-right: 6rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 6rem !important;
}
.ml-10,
.mx-10 {
  margin-left: 6rem !important;
}
.m-15 {
  margin: 9rem !important;
}
.mt-15,
.my-15 {
  margin-top: 9rem !important;
}
.mr-15,
.mx-15 {
  margin-right: 9rem !important;
}
.mb-15,
.my-15 {
  margin-bottom: 9rem !important;
}
.ml-15,
.mx-15 {
  margin-left: 9rem !important;
}
.m-20 {
  margin: 12rem !important;
}
.mt-20,
.my-20 {
  margin-top: 12rem !important;
}
.mr-20,
.mx-20 {
  margin-right: 12rem !important;
}
.mb-20,
.my-20 {
  margin-bottom: 12rem !important;
}
.ml-20,
.mx-20 {
  margin-left: 12rem !important;
}
.m-25 {
  margin: 17rem !important;
}
.mt-25,
.my-25 {
  margin-top: 17rem !important;
}
.mr-25,
.mx-25 {
  margin-right: 17rem !important;
}
.mb-25,
.my-25 {
  margin-bottom: 17rem !important;
}
.ml-25,
.mx-25 {
  margin-left: 17rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-n10 {
  margin: -6rem !important;
}
.mt-n10,
.my-n10 {
  margin-top: -6rem !important;
}
.mr-n10,
.mx-n10 {
  margin-right: -6rem !important;
}
.mb-n10,
.my-n10 {
  margin-bottom: -6rem !important;
}
.ml-n10,
.mx-n10 {
  margin-left: -6rem !important;
}
.m-n15 {
  margin: -9rem !important;
}
.mt-n15,
.my-n15 {
  margin-top: -9rem !important;
}
.mr-n15,
.mx-n15 {
  margin-right: -9rem !important;
}
.mb-n15,
.my-n15 {
  margin-bottom: -9rem !important;
}
.ml-n15,
.mx-n15 {
  margin-left: -9rem !important;
}
.m-n20 {
  margin: -12rem !important;
}
.mt-n20,
.my-n20 {
  margin-top: -12rem !important;
}
.mr-n20,
.mx-n20 {
  margin-right: -12rem !important;
}
.mb-n20,
.my-n20 {
  margin-bottom: -12rem !important;
}
.ml-n20,
.mx-n20 {
  margin-left: -12rem !important;
}
.m-n25 {
  margin: -17rem !important;
}
.mt-n25,
.my-n25 {
  margin-top: -17rem !important;
}
.mr-n25,
.mx-n25 {
  margin-right: -17rem !important;
}
.mb-n25,
.my-n25 {
  margin-bottom: -17rem !important;
}
.ml-n25,
.mx-n25 {
  margin-left: -17rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.px-5 {
  padding-left: 3rem !important;
}
.p-10 {
  padding: 6rem !important;
}
.pt-10,
.py-10 {
  padding-top: 6rem !important;
}
.px-10 {
  padding-right: 6rem !important;
}
.pb-10,
.py-10 {
  padding-bottom: 6rem !important;
}
.px-10 {
  padding-left: 6rem !important;
}
.p-15 {
  padding: 9rem !important;
}
.pt-15,
.py-15 {
  padding-top: 9rem !important;
}
.px-15 {
  padding-right: 9rem !important;
}
.pb-15,
.py-15 {
  padding-bottom: 9rem !important;
}
.px-15 {
  padding-left: 9rem !important;
}
.p-20 {
  padding: 12rem !important;
}
.pt-20,
.py-20 {
  padding-top: 12rem !important;
}
.px-20 {
  padding-right: 12rem !important;
}
.pb-20,
.py-20 {
  padding-bottom: 12rem !important;
}
.px-20 {
  padding-left: 12rem !important;
}
.p-25 {
  padding: 17rem !important;
}
.pt-25,
.py-25 {
  padding-top: 17rem !important;
}
.px-25 {
  padding-right: 17rem !important;
}
.pb-25,
.py-25 {
  padding-bottom: 17rem !important;
}
.px-25 {
  padding-left: 17rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-n10 {
  margin: -6rem !important;
}
.mt-n10,
.my-n10 {
  margin-top: -6rem !important;
}
.mr-n10,
.mx-n10 {
  margin-right: -6rem !important;
}
.mb-n10,
.my-n10 {
  margin-bottom: -6rem !important;
}
.ml-n10,
.mx-n10 {
  margin-left: -6rem !important;
}
.m-n15 {
  margin: -9rem !important;
}
.mt-n15,
.my-n15 {
  margin-top: -9rem !important;
}
.mr-n15,
.mx-n15 {
  margin-right: -9rem !important;
}
.mb-n15,
.my-n15 {
  margin-bottom: -9rem !important;
}
.ml-n15,
.mx-n15 {
  margin-left: -9rem !important;
}
.m-n20 {
  margin: -12rem !important;
}
.mt-n20,
.my-n20 {
  margin-top: -12rem !important;
}
.mr-n20,
.mx-n20 {
  margin-right: -12rem !important;
}
.mb-n20,
.my-n20 {
  margin-bottom: -12rem !important;
}
.ml-n20,
.mx-n20 {
  margin-left: -12rem !important;
}
.m-n25 {
  margin: -17rem !important;
}
.mt-n25,
.my-n25 {
  margin-top: -17rem !important;
}
.mr-n25,
.mx-n25 {
  margin-right: -17rem !important;
}
.mb-n25,
.my-n25 {
  margin-bottom: -17rem !important;
}
.ml-n25,
.mx-n25 {
  margin-left: -17rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-10 {
    margin: 6rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6rem !important;
  }
  .m-sm-15 {
    margin: 9rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 9rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 9rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 9rem !important;
  }
  .m-sm-20 {
    margin: 12rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 12rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 12rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 12rem !important;
  }
  .m-sm-25 {
    margin: 17rem !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 17rem !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 17rem !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 17rem !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 17rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n10 {
    margin: -6rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6rem !important;
  }
  .m-sm-n15 {
    margin: -9rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -9rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -9rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -9rem !important;
  }
  .m-sm-n20 {
    margin: -12rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -12rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -12rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -12rem !important;
  }
  .m-sm-n25 {
    margin: -17rem !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -17rem !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -17rem !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -17rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-10 {
    padding: 6rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6rem !important;
  }
  .px-sm-10 {
    padding-right: 6rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6rem !important;
  }
  .px-sm-10 {
    padding-left: 6rem !important;
  }
  .p-sm-15 {
    padding: 9rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 9rem !important;
  }
  .px-sm-15 {
    padding-right: 9rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 9rem !important;
  }
  .px-sm-15 {
    padding-left: 9rem !important;
  }
  .p-sm-20 {
    padding: 12rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 12rem !important;
  }
  .px-sm-20 {
    padding-right: 12rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 12rem !important;
  }
  .px-sm-20 {
    padding-left: 12rem !important;
  }
  .p-sm-25 {
    padding: 17rem !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 17rem !important;
  }
  .px-sm-25 {
    padding-right: 17rem !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 17rem !important;
  }
  .px-sm-25 {
    padding-left: 17rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n10 {
    margin: -6rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6rem !important;
  }
  .m-sm-n15 {
    margin: -9rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -9rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -9rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -9rem !important;
  }
  .m-sm-n20 {
    margin: -12rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -12rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -12rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -12rem !important;
  }
  .m-sm-n25 {
    margin: -17rem !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -17rem !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -17rem !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -17rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-10 {
    margin: 6rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6rem !important;
  }
  .m-md-15 {
    margin: 9rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 9rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 9rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 9rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 9rem !important;
  }
  .m-md-20 {
    margin: 12rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 12rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 12rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 12rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 12rem !important;
  }
  .m-md-25 {
    margin: 17rem !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 17rem !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 17rem !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 17rem !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 17rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n10 {
    margin: -6rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6rem !important;
  }
  .m-md-n15 {
    margin: -9rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -9rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -9rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -9rem !important;
  }
  .m-md-n20 {
    margin: -12rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -12rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -12rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -12rem !important;
  }
  .m-md-n25 {
    margin: -17rem !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -17rem !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -17rem !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -17rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-10 {
    padding: 6rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6rem !important;
  }
  .px-md-10 {
    padding-right: 6rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6rem !important;
  }
  .px-md-10 {
    padding-left: 6rem !important;
  }
  .p-md-15 {
    padding: 9rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 9rem !important;
  }
  .px-md-15 {
    padding-right: 9rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 9rem !important;
  }
  .px-md-15 {
    padding-left: 9rem !important;
  }
  .p-md-20 {
    padding: 12rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 12rem !important;
  }
  .px-md-20 {
    padding-right: 12rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 12rem !important;
  }
  .px-md-20 {
    padding-left: 12rem !important;
  }
  .p-md-25 {
    padding: 17rem !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 17rem !important;
  }
  .px-md-25 {
    padding-right: 17rem !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 17rem !important;
  }
  .px-md-25 {
    padding-left: 17rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n10 {
    margin: -6rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6rem !important;
  }
  .m-md-n15 {
    margin: -9rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -9rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -9rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -9rem !important;
  }
  .m-md-n20 {
    margin: -12rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -12rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -12rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -12rem !important;
  }
  .m-md-n25 {
    margin: -17rem !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -17rem !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -17rem !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -17rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-10 {
    margin: 6rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6rem !important;
  }
  .m-lg-15 {
    margin: 9rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 9rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 9rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 9rem !important;
  }
  .m-lg-20 {
    margin: 12rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 12rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 12rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 12rem !important;
  }
  .m-lg-25 {
    margin: 17rem !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 17rem !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 17rem !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 17rem !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 17rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n10 {
    margin: -6rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6rem !important;
  }
  .m-lg-n15 {
    margin: -9rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -9rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -9rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -9rem !important;
  }
  .m-lg-n20 {
    margin: -12rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -12rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -12rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -12rem !important;
  }
  .m-lg-n25 {
    margin: -17rem !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -17rem !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -17rem !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -17rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-10 {
    padding: 6rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6rem !important;
  }
  .px-lg-10 {
    padding-right: 6rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6rem !important;
  }
  .px-lg-10 {
    padding-left: 6rem !important;
  }
  .p-lg-15 {
    padding: 9rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 9rem !important;
  }
  .px-lg-15 {
    padding-right: 9rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 9rem !important;
  }
  .px-lg-15 {
    padding-left: 9rem !important;
  }
  .p-lg-20 {
    padding: 12rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 12rem !important;
  }
  .px-lg-20 {
    padding-right: 12rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 12rem !important;
  }
  .px-lg-20 {
    padding-left: 12rem !important;
  }
  .p-lg-25 {
    padding: 17rem !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 17rem !important;
  }
  .px-lg-25 {
    padding-right: 17rem !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 17rem !important;
  }
  .px-lg-25 {
    padding-left: 17rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n10 {
    margin: -6rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6rem !important;
  }
  .m-lg-n15 {
    margin: -9rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -9rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -9rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -9rem !important;
  }
  .m-lg-n20 {
    margin: -12rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -12rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -12rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -12rem !important;
  }
  .m-lg-n25 {
    margin: -17rem !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -17rem !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -17rem !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -17rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-10 {
    margin: 6rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6rem !important;
  }
  .m-xl-15 {
    margin: 9rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 9rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 9rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 9rem !important;
  }
  .m-xl-20 {
    margin: 12rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 12rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 12rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 12rem !important;
  }
  .m-xl-25 {
    margin: 17rem !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 17rem !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 17rem !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 17rem !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 17rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n10 {
    margin: -6rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6rem !important;
  }
  .m-xl-n15 {
    margin: -9rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -9rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -9rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -9rem !important;
  }
  .m-xl-n20 {
    margin: -12rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -12rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -12rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -12rem !important;
  }
  .m-xl-n25 {
    margin: -17rem !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -17rem !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -17rem !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -17rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-10 {
    padding: 6rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6rem !important;
  }
  .px-xl-10 {
    padding-right: 6rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6rem !important;
  }
  .px-xl-10 {
    padding-left: 6rem !important;
  }
  .p-xl-15 {
    padding: 9rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 9rem !important;
  }
  .px-xl-15 {
    padding-right: 9rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 9rem !important;
  }
  .px-xl-15 {
    padding-left: 9rem !important;
  }
  .p-xl-20 {
    padding: 12rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 12rem !important;
  }
  .px-xl-20 {
    padding-right: 12rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 12rem !important;
  }
  .px-xl-20 {
    padding-left: 12rem !important;
  }
  .p-xl-25 {
    padding: 17rem !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 17rem !important;
  }
  .px-xl-25 {
    padding-right: 17rem !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 17rem !important;
  }
  .px-xl-25 {
    padding-left: 17rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n10 {
    margin: -6rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6rem !important;
  }
  .m-xl-n15 {
    margin: -9rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -9rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -9rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -9rem !important;
  }
  .m-xl-n20 {
    margin: -12rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -12rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -12rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -12rem !important;
  }
  .m-xl-n25 {
    margin: -17rem !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -17rem !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -17rem !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -17rem !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -17rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-uppercase {
  text-transform: uppercase !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-primary {
  color: #0061f2 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0042a6 !important;
}
.text-secondary {
  color: #6900c7 !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #41007b !important;
}
.text-success {
  color: #00ac69 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #00603a !important;
}
.text-warning {
  color: #f4a100 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #a86f00 !important;
}
.page-header-dark .btn-link,
.text-light {
  color: #f2f6fc !important;
}
.page-header-dark a.btn-link:focus,
.page-header-dark a.btn-link:hover,
a.text-light:focus,
a.text-light:hover {
  color: #b4cbee !important;
}
.page-header-light .btn-link,
.text-dark {
  color: #212832 !important;
}
.page-header-light a.btn-link:focus,
.page-header-light a.btn-link:hover,
a.text-dark:focus,
a.text-dark:hover {
  color: #030304 !important;
}
.text-black {
  color: #000 !important;
}
a.text-black:focus,
a.text-black:hover {
  color: #000 !important;
}
.text-blue {
  color: #0061f2 !important;
}
a.text-blue:focus,
a.text-blue:hover {
  color: #0042a6 !important;
}
.text-body {
  color: #69707a !important;
}
.text-muted {
  color: #a7aeb8 !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.page-header-dark .page-header-text {
  color: rgba(255, 255, 255, 0.5) !important;
}
.footer a {
  color: inherit !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  img {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
}
body,
html {
  height: 100%;
}
body {
  overflow-x: hidden;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 3rem;
  padding-right: 3rem;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Light.ttf);
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Light.ttf);
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Light.ttf);
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Light.ttf);
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Condensed.ttf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Condensed.ttf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Bold.ttf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-BoldItalic.ttf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Black.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Black.ttf);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-CondensedBold.ttf);
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Condensed.ttf);
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Regular.ttf);
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "72";
  src: url(../fonts/72-Italic.ttf);
  font-weight: 800;
  font-style: italic;
}
#layoutDefault {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#layoutDefault #layoutDefault_content {
  min-width: 0;
  flex-grow: 1;
}
#layoutDefault #layoutDefault_footer {
  min-width: 0;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 0.75rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 0.75rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}
.bg-gray-100 {
  background-color: #f2f6fc !important;
}
.bg-gray-200 {
  background-color: #e0e5ec !important;
}
.bg-gray-300 {
  background-color: #d4dae3 !important;
}
.bg-gray-400 {
  background-color: #c5ccd6 !important;
}
.bg-gray-500 {
  background-color: #a7aeb8 !important;
}
.bg-gray-600 {
  background-color: #69707a !important;
}
.bg-gray-700 {
  background-color: #4a515b !important;
}
.bg-gray-800 {
  background-color: #363d47 !important;
}
.bg-gray-900 {
  background-color: #212832 !important;
}
.bg-img-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/header.svg);
}
.overlay {
  position: relative;
}
.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}
.overlay-primary:before {
  background-color: #0061f2 !important;
}
.overlay-secondary:before {
  background-color: #6900c7 !important;
}
.overlay-success:before {
  background-color: #00ac69 !important;
}
.overlay-warning:before {
  background-color: #f4a100 !important;
}
.overlay-light:before {
  background-color: #f2f6fc !important;
}
.overlay-dark:before {
  background-color: #212832 !important;
}
.overlay-black:before {
  background-color: #000 !important;
}
.overlay-blue:before {
  background-color: #0061f2 !important;
}
.overlay-10:before {
  opacity: 0.1;
}
.overlay-20:before {
  opacity: 0.2;
}
.overlay-30:before {
  opacity: 0.3;
}
.overlay-40:before {
  opacity: 0.4;
}
.overlay-50:before {
  opacity: 0.5;
}
.overlay-60:before {
  opacity: 0.6;
}
.overlay-70:before {
  opacity: 0.7;
}
.overlay-80:before {
  opacity: 0.8;
}
.overlay-90:before {
  opacity: 0.9;
}
.border-lg {
  border-width: 0.25rem !important;
}
.border-right-lg {
  border-right-width: 0.25rem !important;
}
.border-bottom-lg {
  border-bottom-width: 0.25rem !important;
}
.border-primary {
  border-color: #0061f2 !important;
}
.border-secondary {
  border-color: #6900c7 !important;
}
.border-success {
  border-color: #00ac69 !important;
}
.border-warning {
  border-color: #f4a100 !important;
}
.border-light {
  border-color: #f2f6fc !important;
}
.border-dark {
  border-color: #212832 !important;
}
.border-black {
  border-color: #000 !important;
}
.border-blue {
  border-color: #0061f2 !important;
}
.rounded-xl {
  border-radius: 1rem !important;
}
.border-gray-100 {
  border-color: #f2f6fc !important;
}
.border-gray-200 {
  border-color: #e0e5ec !important;
}
.border-gray-300 {
  border-color: #d4dae3 !important;
}
.border-gray-400 {
  border-color: #c5ccd6 !important;
}
.border-gray-500 {
  border-color: #a7aeb8 !important;
}
.border-gray-600 {
  border-color: #69707a !important;
}
.border-gray-700 {
  border-color: #4a515b !important;
}
.border-gray-800 {
  border-color: #363d47 !important;
}
.border-gray-900 {
  border-color: #212832 !important;
}
.text-gray-100 {
  color: #f2f6fc !important;
}
.text-gray-200 {
  color: #e0e5ec !important;
}
.text-gray-300 {
  color: #d4dae3 !important;
}
.text-gray-400 {
  color: #c5ccd6 !important;
}
.text-gray-500 {
  color: #a7aeb8 !important;
}
.text-gray-600 {
  color: #69707a !important;
}
.text-gray-700 {
  color: #4a515b !important;
}
.text-gray-800 {
  color: #363d47 !important;
}
.text-gray-900 {
  color: #212832 !important;
}
.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}
.page-header-dark .page-header-text a {
  color: rgba(255, 255, 255, 0.75);
}
.text-xs {
  font-size: 0.75rem !important;
}
.text-uppercase-expanded {
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.z-1 {
  z-index: 1 !important;
  position: relative !important;
}
.z-2 {
  z-index: 2 !important;
  position: relative !important;
}
.alert-icon {
  position: relative;
  display: flex;
  padding: 0;
}
.alert-icon .alert-icon-content {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-icon {
  padding: 0;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  height: calc((0.875rem * 1) + (0.875rem * 2) + (2px)) !important;
  width: calc((0.875rem * 1) + (0.875rem * 2) + (2px)) !important;
}
.btn-icon .feather {
  margin-top: 0 !important;
}
.btn-icon.btn-xl {
  height: calc((1.125rem * 1) + (1.25rem * 2) + (2px)) !important;
  width: calc((1.125rem * 1) + (1.25rem * 2) + (2px)) !important;
}
.btn-icon.btn-lg {
  height: calc((1rem * 1) + (1.125rem * 2) + (2px)) !important;
  width: calc((1rem * 1) + (1.125rem * 2) + (2px)) !important;
}
.btn-icon.btn-sm {
  height: calc((0.75rem * 1) + (0.5rem * 2) + (2px)) !important;
  width: calc((0.75rem * 1) + (0.5rem * 2) + (2px)) !important;
}
.btn-icon.btn-xs {
  height: calc((0.7rem * 1) + (0.25rem * 2) + (2px)) !important;
  width: calc((0.7rem * 1) + (0.25rem * 2) + (2px)) !important;
}
.btn-icon.btn-link {
  text-decoration: none;
}
.btn .feather {
  margin-top: -1px;
  height: 0.875rem;
  width: 0.875rem;
}
.btn-lg .feather {
  height: 1rem;
  width: 1rem;
}
.btn-sm .feather {
  height: 0.75rem;
  width: 0.75rem;
}
.btn-xs .feather {
  height: 0.7rem;
  width: 0.7rem;
}
.btn-xl .feather {
  height: 1.125rem;
  width: 1.125rem;
}
.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.35rem;
  line-height: 1;
}
.btn-xl {
  padding: 1.25rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}
.btn-facebook:focus {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-github {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-github:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}
.btn-github:focus {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}
.btn-github:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-github:not(:disabled):not(.disabled).active,
.btn-github:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}
.btn-github:not(:disabled):not(.disabled).active:focus,
.btn-github:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}
.btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-google:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
}
.btn-google:focus {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
  box-shadow: 0 0 0 0.2rem rgba(237, 95, 83, 0.5);
}
.btn-google:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.btn-google:not(:disabled):not(.disabled).active,
.btn-google:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #d62516;
  border-color: #ca2315;
}
.btn-google:not(:disabled):not(.disabled).active:focus,
.btn-google:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 95, 83, 0.5);
}
.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:hover {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}
.btn-twitter:focus {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}
.btn-twitter:disabled {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled).active,
.btn-twitter:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0c85d0;
  border-color: #0b7ec4;
}
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.btn-twitter:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}
.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  overflow: hidden;
  position: relative;
}
.card .card-header {
  display: flex;
  align-items: center;
  font-weight: 600;
  border-bottom: none;
}
.card:not([class*="bg-"]) .card-header {
  color: #0061f2;
}
.card-link {
  text-decoration: none !important;
  color: inherit !important;
}
.card-link:hover {
  color: inherit !important;
}
.navbar-marketing {
  transition: background-color 0.15s ease-in-out;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.navbar-marketing .navbar-toggler {
  padding: 0.5rem;
  border: 0;
}
.navbar-marketing .navbar-toggler svg {
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  color: #222;
}
.navbar-marketing .navbar-brand {
  font-size: 1.2rem;
  font-weight: 700;
}
.navbar-marketing .navbar-brand img {
  height: 1rem;
}
.navbar-marketing .navbar-brand svg {
  height: 1rem;
  fill: currentColor;
}
.navbar-marketing .navbar-nav {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.navbar-marketing .navbar-nav .nav-item .nav-link {
  font-weight: 500;
}
.page-header.navbar-fixed .navbar-marketing.navbar {
  max-height: 100vh;
  overflow-y: auto;
}
@media (min-width: 992px) {
  .navbar-marketing {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-marketing .navbar-nav {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .navbar-marketing .navbar-nav .nav-item {
    margin-right: 1rem;
  }
  .navbar-marketing .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .page-header.navbar-fixed .navbar-marketing.navbar {
    max-height: none;
    overflow-y: visible;
  }
}
@media (max-width: 991.98px) {
  .animated--fade-in {
    -webkit-animation: none;
    animation: none;
  }
}
i-feather {
  display: inline-flex !important;
}
i-feather svg {
  height: 100%;
  width: 100%;
  vertical-align: top;
}
.feather {
  height: 1rem;
  width: 1rem;
  vertical-align: top;
}
.icon-list-social {
  display: flex;
}
.icon-list-social .icon-list-social-link {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.badge-marketing {
  padding: 0.5em 1em;
}
.badge-sm {
  font-size: 0.75rem;
}
.badge-md {
  font-size: 1rem;
}
.badge-lg {
  font-size: 1.25rem;
}
section {
  position: relative;
}
.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1rem;
  width: 100%;
}
@media (min-width: 576px) {
  .svg-border-rounded svg {
    height: 1.5rem;
  }
}
@media (min-width: 768px) {
  .svg-border-rounded svg {
    height: 2rem;
  }
}
@media (min-width: 992px) {
  .svg-border-rounded svg {
    height: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .svg-border-rounded svg {
    height: 3rem;
  }
}
.page-header {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.page-header .page-header-content .page-header-title {
  font-size: 4.5rem;
  font-weight: 600;
}
.page-header .page-header-content .page-header-text {
  font-size: 1.6rem;
}
.page-header .page-header-content .page-header-text.small {
  font-size: 0.9rem;
}
.page-header-dark {
  color: #fff;
  background-color: #212832;
}
.page-header-dark .page-header-title {
  color: #fff;
  font-size: 2.5rem;
}
.page-header-light {
  background-color: #f2f6fc;
}
.page-header-light .page-header-text {
  color: #69707a;
}
.page-header-light .page-header-text a {
  color: #4a515b;
}
.page-header.navbar-fixed .page-header-content {
  padding-top: 12.5rem;
}
.footer {
  font-size: 0.875rem;
}
.footer .footer-brand {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.footer .footer-brand svg {
  height: 1rem;
}
.footer.footer-dark {
  color: rgba(255, 255, 255, 0.6);
}
.footer.footer-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}
.footer.footer-light {
  color: #a7aeb8;
} /*! * html5-device-mockups () * Copyright 2013 - 2018 pixelsign * Licensed under MIT () * Last Build: Thu Dec 20 2018 14:05:50 */
.device {
  position: relative;
  background-size: cover;
}
.device::after {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.device .button {
  position: absolute;
  cursor: pointer;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.89807163%;
  z-index: initial;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 50.27700831%;
  z-index: initial;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.898071625%;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .button {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 50.2770083102%;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .button {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.post-archive-tag {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #e0e5ec;
  margin-bottom: 3rem;
  padding-bottom: 0.5rem;
}
.post-archive-item {
  color: inherit !important;
  text-decoration: none !important;
}
.post-archive-meta {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.post-archive-meta .post-archive-meta-img {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.post-archive-meta .post-archive-meta-details .post-archive-meta-details-name {
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.post-archive-meta .post-archive-meta-details .post-archive-meta-details-date {
  font-size: 0.75rem;
  line-height: 1;
}
.single-post .single-post-meta {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.single-post .single-post-meta .single-post-meta-img {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.single-post
  .single-post-meta
  .single-post-meta-details
  .single-post-meta-details-name {
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.single-post
  .single-post-meta
  .single-post-meta-details
  .single-post-meta-details-date {
  font-size: 0.75rem;
  line-height: 1;
}
.single-post .single-post-meta-links {
  font-size: 1.25rem;
}
.single-post .single-post-meta-links a {
  color: #c5ccd6;
  margin-left: 0.5rem;
}
.single-post .single-post-meta-links a:hover {
  color: #69707a;
}
.single-post .single-post-text {
  font-size: 1.1rem;
}
.single-post .single-post-text p {
  margin-bottom: 1.5rem;
}
.single-post .single-post-text h2,
.single-post .single-post-text h3,
.single-post .single-post-text h4,
.single-post .single-post-text h5,
.single-post .single-post-text h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
.header-image {
  background-image: url(../images/header.svg);
}
textarea.form-control {
  height: auto;
  width: auto;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 1.75rem + 2px);
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #69707a;
}
.form-control:focus {
  color: #69707a;
  background-color: #fff;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.25);
}
.form-control::-moz-placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.form-control::placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e0e5ec;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

select.form-control:focus::-ms-value {
  color: #69707a;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  font-size: 1rem;
  line-height: 1;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.75rem;
  line-height: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.875rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: #69707a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1em + 2.25rem + 2px);
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
  width: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #a7aeb8;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00ac69;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 172, 105, 0.9);
  border-radius: 0.35rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00ac69;
  padding-right: calc(1em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300ac69' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.4375rem) center;
  background-size: calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1em + 1.75rem);
  background-position: top calc(0.25em + 0.4375rem) right calc(0.25em + 0.4375rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00ac69;
  padding-right: calc(0.75em + 3.4375rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23363d47' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.125rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300ac69' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.125rem/calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00ac69;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00ac69;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00ac69;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00df88;
  background-color: #00df88;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00ac69;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00ac69;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e81500;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(232, 21, 0, 0.9);
  border-radius: 0.35rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e81500;
  padding-right: calc(1em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e81500' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e81500' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.4375rem) center;
  background-size: calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e81500;
  box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1em + 1.75rem);
  background-position: top calc(0.25em + 0.4375rem) right calc(0.25em + 0.4375rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e81500;
  padding-right: calc(0.75em + 3.4375rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23363d47' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.125rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e81500' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e81500' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2.125rem/calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e81500;
  box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e81500;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e81500;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e81500;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff311c;
  background-color: #ff311c;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e81500;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e81500;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e81500;
  box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
flex-flow: row wrap;
